<template>
  <content-loader
    :width="svgSize"
    :height="svgSize"
    class="squircle-placeholder"
    :class="`squircle-placeholder--${size}`"
  >
    <path
      d="M0 14.5C0 3.5 3.5 0 14.5 0H17.5C28.5 0 32 3.5 32 14.5V17.5C32 28.5 28.5 32 17.5 32H14.5C3.5 32 0 28.5 0 17.5V14.5Z"
      :fill="color || '#EBF1FF'"
    />
  </content-loader>
</template>

<script setup lang="ts">
import { ContentLoader } from "vue-content-loader";

interface IProps {
  size?: "s" | "m" | "l";
  color?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  size: "m",
  color: "#f3f3f3",
});

const svgSize = computed(() => {
  if (props.size === "s") {
    return 32;
  }

  if (props.size === "m") {
    return 40;
  }

  return 64;
});
</script>

<style lang="scss" scoped>
.squircle-placeholder {
  margin-left: $uds-spacing-1_5;
  margin-top: 10px;

  &--s {
    margin-top: 0;
    width: 32px;
    height: 32px;
  }

  &--m {
    width: 40px;
    height: 40px;
  }

  &--l {
    width: 64px;
    height: 64px;
  }
}
</style>
